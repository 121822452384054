import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo } from "react";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import faAddToList from "styles/icons/faAddToList";

const baseStyles = {
  addToListIcon: {
    width: "1rem",
    height: "1rem",

    border: "none",
    margin: 0,
    padding: 0,

    ":hover": {
      cursor: "pointer",
    },
  },
  full: {
    width: "max-content",
    height: "2rem",
    padding: "0 1rem",
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    borderRadius: 25,
    border: `1px solid ${colours.borderGrey}`,
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
  },
  onList: {
    color: colours.primary,
  },
};

export default function AddToList(props) {
  const {
    entity,
    entityId,
    entityType,
    pageType,
    withLabel,
    analyticsProps,
    context,
  } = props;
  const { showModal } = useActionCreators(modalActions);

  const { styles, css } = useStyles(baseStyles, props);

  const isInListt = useMemo(() => {
    const userData = entity?.get("user_data");
    const listArray = userData?.get("in_lists");

    return listArray?.size > 0;
  }, [entity]);

  const handleAddToListClick = useCallback(() => {
    showModal("prompt", {
      promptType: "list",
      entity: entity,
      entity_type: entityType,
      page: pageType,
      context,
    });

    sendGAEvent({
      action: "cardActionClick",
      context: "TableView",
      entity_name: entityType,
      entity_id: entityId,
      card_action_type: "ListActionInsideTableView",
      pageType,
      ...(analyticsProps || {}),
    });
  }, [
    showModal,
    pageType,
    entity,
    entityType,
    entityId,
    analyticsProps,
    context,
  ]);

  const label = useMemo(() => {
    if (isInListt) {
      return "Added";
    }

    return "Add to List";
  }, [isInListt]);

  return (
    <button
      data-testid="addToList"
      className={css(
        styles.addToListIcon,
        withLabel && styles.full,
        isInListt && styles.onList
      )}
      onClick={handleAddToListClick}
    >
      <FontAwesomeIcon icon={faAddToList} dataid="addToList" />
      {withLabel && label}
    </button>
  );
}
