import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { memo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import useLoggedInUser from "hooks/useLoggedInUser";

const ExportsActivity = () => {
  const user = useLoggedInUser();
  const exportsCount = user ? user.get("export_count") : 0;

  return (
    <SidebarLinkPanel
      pathname="/profile/pro/exports"
      icon={faDownload}
      title="Exports"
      count={exportsCount}
    />
  );
};

export default memo(ExportsActivity);
