import { memo, useMemo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import useLoggedInUser from "hooks/useLoggedInUser";

import faFollow from "styles/icons/faFollow";

const FollowsActivity = () => {
  const user = useLoggedInUser();

  const followCountConfigs = useMemo(
    () => [
      {
        count: user.get("podcast_follow_count", 0),
        type: "podcast",
        pathname: "/profile/followed/podcasts",
      },
      {
        count: user.get("creator_follow_count", 0),
        type: "creator",
        pathname: "/profile/followed/creators",
      },
      {
        count: user.get("user_follow_count", 0),
        type: "user",
        pathname: "/profile/followed/users",
      },
      {
        count: user.get("userlist_follow_count", 0),
        type: "list",
        pathname: "/profile/followed/lists",
      },
    ],
    [user]
  );

  return (
    <SidebarLinkPanel
      pathname="/profile/followed"
      icon={faFollow}
      title="Follows"
      hideSmallIcon
      counts={followCountConfigs}
    />
  );
};

export default memo(FollowsActivity);
