import { faComment } from "@fortawesome/free-solid-svg-icons/faComment";
import { memo, useMemo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import useLoggedInUser from "hooks/useLoggedInUser";

const ReviewsActivity = () => {
  const user = useLoggedInUser();

  const reviewCountConfigs = useMemo(
    () => [
      {
        count: user.get("episode_review_count", 0),
        type: "episode",
        pathname: "/profile/reviewed/episodes",
      },
      {
        count: user.get("podcast_review_count", 0),
        type: "podcast",
        pathname: "/profile/reviewed/podcasts",
      },
    ],
    [user]
  );

  return (
    <SidebarLinkPanel
      pathname="/profile/reviewed"
      icon={faComment}
      title="Reviews"
      hideSmallIcon
      counts={reviewCountConfigs}
    />
  );
};

export default memo(ReviewsActivity);
