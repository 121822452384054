import { faBellOn } from "@fortawesome/pro-solid-svg-icons/faBellOn";
import React from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import useLoggedInUser from "hooks/useLoggedInUser";

const AlertsActivity = () => {
  const user = useLoggedInUser();
  const alertsUsed = user ? user.get("alerts_used") : 0;

  return (
    <SidebarLinkPanel
      pathname="/profile/alerts"
      icon={faBellOn}
      title="My Alerts"
      count={alertsUsed}
    />
  );
};

export default React.memo(AlertsActivity);
