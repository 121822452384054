import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { memo, useMemo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import useLoggedInUser from "hooks/useLoggedInUser";

const RatingsActivity = () => {
  const user = useLoggedInUser();

  const ratingCountConfigs = useMemo(
    () => [
      {
        count: user.get("episode_rating_count", 0),
        type: "episode",
        pathname: "/profile/rated/episodes",
      },
      {
        count: user.get("podcast_rating_count", 0),
        type: "podcast",
        pathname: "/profile/rated/podcasts",
      },
    ],
    [user]
  );

  return (
    <SidebarLinkPanel
      pathname="/profile/rated"
      icon={faStar}
      title="Ratings"
      hideSmallIcon
      counts={ratingCountConfigs}
    />
  );
};

export default memo(RatingsActivity);
