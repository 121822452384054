import { faBookmark } from "@fortawesome/free-solid-svg-icons/faBookmark";
import { memo, useMemo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import useLoggedInUser from "hooks/useLoggedInUser";

const BookmarksActivity = () => {
  const user = useLoggedInUser();

  const bookmarkCount =
    user &&
    user.get("podcast_bookmark_count", 0) +
      user.get("episode_bookmark_count", 0);

  const bookmarkCountConfigs = useMemo(
    () => [
      {
        count: user.get("episode_bookmark_count", 0),
        type: "episode",
        pathname: "/profile/bookmarks/episodes",
      },
      {
        count: user.get("podcast_bookmark_count", 0),
        type: "podcast",
        pathname: "/profile/bookmarks/podcasts",
      },
    ],
    [user]
  );

  return (
    <SidebarLinkPanel
      pathname="/profile/bookmarks"
      icon={faBookmark}
      title="Bookmarks"
      count={bookmarkCount}
      counts={bookmarkCountConfigs}
    />
  );
};

export default memo(BookmarksActivity);
