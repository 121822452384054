import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const loadSavedSearches = createRoutine("LOAD_SAVED_SEARCHES");
export const loadSavedSearchesPromise = promisifyRoutine(loadSavedSearches);

export const deleteSavedSearch = createRoutine("DELETE_SAVED_SEARCH");
export const deleteSavedSearchPromise = promisifyRoutine(deleteSavedSearch);

export const saveSearch = createRoutine("SAVE_SEARCH");
export const saveSearchPromise = promisifyRoutine(saveSearch);
