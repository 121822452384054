import PropTypes from "prop-types";
import { memo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import { loadSpecificPodcast as loadSpecificPodcastAction } from "actions/podcast";
import {
  selectSpecificPodcast,
  selectPodcastLoading,
  selectPodcastFailed,
} from "selectors/podcast";
import cachedImage from "utils/entity/cachedImage";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useActionCreators from "hooks/useActionCreators";
import useLoadEntity from "hooks/useLoadEntity";

function HomepageSidebarClaimedPodcast(props) {
  const { podcast_id } = props;

  const { loadSpecificPodcast } = useActionCreators({
    loadSpecificPodcast: loadSpecificPodcastAction,
  });

  const { entity: pod } = useLoadEntity({
    entity_type: "podcast",
    entity_id: podcast_id,
    loader: loadSpecificPodcast,
    selector: selectSpecificPodcast,
    loadingSelector: selectPodcastLoading,
    failedSelector: selectPodcastFailed,
  });

  if (!pod) {
    console.error("Claimed podcast is missing");
    return null;
  }

  const podcastNumberOfEpisodesPlusNumberOfReviews =
    pod.get("number_of_episodes", 0) + pod.get("number_of_reviews", 0);

  const showCounts = podcastNumberOfEpisodesPlusNumberOfReviews > 0;

  return (
    <SidebarLinkPanel
      pathname={`/profile/claimed/${pod.get("id")}`}
      title={pod.get("title")}
      image={cachedImage(pod.get("image_url"), 36 * 2)}
      newTab
      gaIdentifier="claimedPodcast"
      counts={
        showCounts
          ? [
              {
                count: pod.get("number_of_episodes"),
                type: "episode",
                pathname: getPodcastUrl(pod, "/episodes"),
              },
              {
                count: pod.get("review_count"),
                type: "review",
                pathname: getPodcastUrl(pod, "/reviews"),
              },
              {
                count: pod.get("follower_count"),
                type: "follower",
              },
            ]
          : undefined
      }
    />
  );
}

HomepageSidebarClaimedPodcast.propTypes = {
  podcast_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default memo(HomepageSidebarClaimedPodcast);
