import { faAward } from "@fortawesome/free-solid-svg-icons/faAward";
import { memo, useMemo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import useLoggedInUser from "hooks/useLoggedInUser";

const BadgesActivity = () => {
  const user = useLoggedInUser();

  const badgesCountConfig = useMemo(
    () => [
      {
        count: user.get("badge_count", 0),
        type: "unlocked",
        pathname: "/profile/badges",
        dontPluralize: true,
      },
    ],
    [user]
  );

  return (
    <SidebarLinkPanel
      pathname="/profile/badges"
      icon={faAward}
      title="Badges"
      hideSmallIcon
      counts={badgesCountConfig}
    />
  );
};

export default memo(BadgesActivity);
