import { css } from "aphrodite";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import EntityImage from "components/Entities/EntityImage";
import ImageFallback from "components/Global/Images/ImageFallback";
import ProfileNavigationSidebarStyles from "components/Profiles/ProfileNavigationSidebar/ProfileNavigationSidebarStyles";
import SubtitleCount from "components/Profiles/ProfileNavigationSidebar/SubtitleCount";
import ProfilePanelIcon from "components/Profiles/ProfilePanelIcon";

import { indexKey } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

const baseStyles = ProfileNavigationSidebarStyles;

const imageFallbackStyles = {
  imageFallback: {
    width: "100%",
    height: "auto",
  },
};

function SidebarLinkPanel(props) {
  const {
    pathname,
    horizontal,
    hideSecondaryIcon,
    icon,
    imageType,
    imageTitle,
    imageAlt,
    image,
    title,
    counts,
    count: passedCount,
    afterTitle,
    description,
    newTab,
    gaIdentifier,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const location = useLocation();

  const isActive = location.pathname.indexOf(pathname) === 0;

  const count = useMemo(() => {
    if (
      passedCount &&
      typeof passedCount === "object" &&
      Object.prototype.hasOwnProperty.call(passedCount, "count") &&
      Object.prototype.hasOwnProperty.call(passedCount, "type")
    ) {
      return passedCount;
    }
    if (typeof passedCount === "number") {
      return {
        type: "item",
        count: passedCount,
      };
    }
    return {
      type: "item",
      count: 0,
    };
  }, [passedCount]);

  const handleTitleClick = useCallback(() => {
    sendGAEvent({
      action: "sidePanelTitleClick",
      panelTitle: gaIdentifier || title,
      pathname,
    });
  }, [gaIdentifier, title, pathname]);

  const OuterComponent = counts ? "span" : Link;
  const InnerComponent = counts ? Link : "span";

  return (
    <OuterComponent
      className={css(
        styles.profileNavigationSidebarPanel,
        !counts && styles.profileNavigationSidebarPanelLink,
        horizontal && styles.profileNavigationSidebarPanelHorizontal,
        isActive && styles.profileNavigationSidebarPanelActive
      )}
      to={pathname}
      key={pathname}
      aria-label={imageTitle || title || "Sidebar Link"}
    >
      <span
        className={css(
          styles.profileNavigationSidebarPanelBar,
          isActive && styles.profileNavigationSidebarPanelBarActive
        )}
      />
      <span
        className={css(
          styles.imageIconContainer,
          horizontal && styles.imageIconContainerHorizontal
        )}
      >
        {icon && (
          <InnerComponent
            aria-label={imageTitle || title || "Sidebar Link"}
            to={pathname}
            target={newTab ? "_blank" : undefined}
            onClick={handleTitleClick}
            className={css(styles.panelImage)}
          >
            <ProfilePanelIcon
              icon={icon}
              hideSecondaryIcon={hideSecondaryIcon}
              horizontal={horizontal}
              isActive={isActive}
            />
          </InnerComponent>
        )}

        {image && (
          <InnerComponent
            aria-label={imageTitle || title || "Sidebar Link"}
            to={pathname}
            target={newTab ? "_blank" : undefined}
            onClick={handleTitleClick}
            className={css(
              styles.panelImage,
              imageType === "person" ? styles.personImage : styles.blockImage
            )}
          >
            {imageType === "person" ? (
              <EntityImage
                image_url={image}
                cacheSize={72}
                title={imageTitle || title}
                alt={imageAlt || title}
                fullWidth
                width={36}
                height={36}
                profileImage
              />
            ) : (
              <ImageFallback
                src={image}
                styles={imageFallbackStyles}
                title={imageTitle || title}
                alt={imageAlt || title}
                width={36}
                height={36}
              />
            )}
          </InnerComponent>
        )}
      </span>

      <span
        className={css(
          styles.profileNavigationSidebarPanelInfo,
          horizontal && styles.profileNavigationSidebarPanelInfoHorizontal
        )}
      >
        <InnerComponent
          className={css(
            styles.profileNavigationSidebarPanelTitle,
            isActive && styles.profileNavigationSidebarPanelTitleActive
          )}
          to={pathname}
          target={newTab ? "_blank" : undefined}
          onClick={handleTitleClick}
          aria-label={imageTitle || title || "Sidebar Link"}
        >
          <span>{title}</span>
          {afterTitle && (
            <span
              className={css(styles.profileNavigationSidebarPanelTitleAfter)}
            >
              {afterTitle}
            </span>
          )}
        </InnerComponent>
        <span className={css(styles.profileNavigationSidebarPanelItemCount)}>
          {description ||
            (counts &&
              counts.map((item, index) => (
                <SubtitleCount
                  key={indexKey(index)}
                  item={item}
                  newTab={newTab}
                  panelTitle={title}
                  isFirst={index === 0}
                />
              ))) ||
            `${count.count} ${
              count.dontPluralize
                ? count.type
                : pluralize(count.type, count.count)
            }`}
        </span>
      </span>
    </OuterComponent>
  );
}

SidebarLinkPanel.propTypes = {
  pathname: PropTypes.string,
  horizontal: PropTypes.bool,
  hideSecondaryIcon: PropTypes.bool,
  icon: PropTypes.object,
  imageType: PropTypes.string,
  imageTitle: PropTypes.node,
  imageAlt: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.node,
  counts: PropTypes.array,
  count: PropTypes.number,
  afterTitle: PropTypes.node,
  description: PropTypes.node,
  newTab: PropTypes.bool,
  gaIdentifier: PropTypes.string,
};

SidebarLinkPanel.defaultProps = {
  pathname: null,
  horizontal: false,
  hideSecondaryIcon: false,
  icon: null,
  imageType: null,
  imageTitle: null,
  imageAlt: null,
  image: null,
  title: null,
  counts: null,
  count: null,
  afterTitle: null,
  description: null,
  newTab: false,
  gaIdentifier: null,
};

export default memo(SidebarLinkPanel);
