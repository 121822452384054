import PropTypes from "prop-types";
import { memo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import creatorActions from "actions/creator";
import {
  selectSpecificCreator,
  selectCreatorLoading,
  selectCreatorFailed,
} from "selectors/creator";
import { log } from "utils/dev";
import cachedImage from "utils/entity/cachedImage";
import getCreatorDisplayName from "utils/entity/getCreatorDisplayName";
import getCreatorUrl from "utils/entity/getCreatorUrl";
import getEntityImageUrl from "utils/entity/getEntityImageUrl";

import useActionCreators from "hooks/useActionCreators";
import useLoadEntity from "hooks/useLoadEntity";
import useLoggedInUser from "hooks/useLoggedInUser";

function HomepageSidebarManagedCreator(props) {
  const { creator_id } = props;

  const { loadSpecificCreator } = useActionCreators({
    loadSpecificCreator: creatorActions.loadSpecificCreator,
  });

  const { entity: creator } = useLoadEntity({
    entity_type: "creator",
    entity_id: creator_id,
    loader: loadSpecificCreator,
    selector: selectSpecificCreator,
    loadingSelector: selectCreatorLoading,
    failedSelector: selectCreatorFailed,
  });
  const user = useLoggedInUser();

  if (!creator) {
    log(`Creator ${creator_id} couldn't be fetched`);

    return null;
  }

  return (
    <SidebarLinkPanel
      pathname={getCreatorUrl(creator)}
      title={creator.get("name")}
      afterTitle={
        user && creator.get("id") === user.get("creator") ? "(You)" : ""
      }
      description={`${creator.get(
        "episode_credit_count"
      )} credits, ${creator.get("follower_count")} followers`}
      image={cachedImage(getEntityImageUrl(creator, "creator"), 36 * 2)}
      imageType="person"
      imageAlt={getCreatorDisplayName(creator)}
      imageTitle={`${getCreatorDisplayName(creator)}`}
      gaIdentifier="managedCreator"
    />
  );
}

HomepageSidebarManagedCreator.propTypes = {
  creator_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(HomepageSidebarManagedCreator);
