import { fromJS } from "immutable";
import { SAVE_SEARCHES_MODULE } from "modules/moduleIds";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  loadSavedSearchesPromise,
  deleteSavedSearchPromise,
} from "routines/saveSearches";

import {
  selectSavedSearchesLoading,
  selectSavedSearchesLoaded,
  selectSavedSearchesError,
  selectSavedSearches,
} from "selectors/search";
import sendGAEvent from "utils/sendGAEvent";

import useAddModules from "hooks/useAddModules";
import useFrontendSetting from "hooks/useFrontendSetting";
import useLoggedInUser from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";
import useUserHasPro from "hooks/useUserHasPro";

const DEFAULT_PLACEHOLDER_SEARCHES = fromJS([
  {
    placeholderId: "business",
    url:
      "/categories/business/podcasts/top?filter=eyJwb2RjYXN0X3N0YXR1cy" +
      "I6eyJ2YWx1ZSI6WyJhY3RpdmUiXX0sImxhbmd1YWdlIjp7InZhbHVlIjpbImVuIl19" +
      "fQ%3D%3D&filterTitle=QWN0aXZlIFN0YXR1cyBhbmQgTGFuZ3VhZ2U%3D",
    name: "Top Active Business Podcasts",
    entity_type: "podcast",
  },
  {
    placeholderId: "userReviews",
    url: "/search/users/q/$£*^?sort=reviewCount",
    name: "Users with the Most Reviews",
    entity_type: "user",
  },
  {
    placeholderId: "voiceActors",
    url: '/search/creators/q/"voice actor"?sort=appearanceCount',
    name: "Voice Actors",
    entity_type: "creator",
  },
]);

const PRO_PLACEHOLDER_SEARCHES = fromJS([
  {
    placeholderId: "upAndComing",
    url:
      "/podcasts/new?filter=eyJwb3dlcl9zY29yZSI6eyJ2YWx1ZSI6WyI5MCIs" +
      "IjgwIiwiNzAiXX0sInBvZGNhc3Rfc3RhdHVzIjp7InZhbHVlIjpbImFjdGl2ZSJdf" +
      "X0%253D&filterTitle=UG93ZXIgU2NvcmUsIEFjdGl2ZSBTdGF0dXMgYW5kIExhbmd1YWdl",
    name: "Up & Coming Podcasts",
    entity_type: "podcast",
  },
  {
    placeholderId: "business",
    url:
      "/categories/business/podcasts?filter=eyJwb2RjYXN0X3N0YXR1cyI6e" +
      "yJ2YWx1ZSI6WyJhY3RpdmUiXX0sImxhbmd1YWdlIjp7InZhbHVlIjpbImVuIl19fQ" +
      "%3D%3D&filterTitle=QWN0aXZlIFN0YXR1cyBhbmQgTGFuZ3VhZ2U%3D",
    name: "Top Active Business Podcasts",
    entity_type: "podcast",
  },
  {
    placeholderId: "pepsi",
    url: "/search/episodes/q/Pepsi?sort=new",
    name: 'New Episodes that Mention "Pepsi"',
    entity_type: "episode",
  },
]);

const HIDE_PLACEHOLDER_SEARCH_KEY = "podchaser:hide_placeholder_saved_searches";

export function useDeletePlaceholderSavedSearch() {
  const [hidePlaceholderSavedSearches, setHidePlaceholderSavedSearches] =
    useFrontendSetting(HIDE_PLACEHOLDER_SEARCH_KEY, []);

  const { deleteSavedSearch } = useRoutinePromises({
    deleteSavedSearch: deleteSavedSearchPromise,
  });

  return useCallback(
    (search) => {
      const searchId = search?.get("id");
      const placeholderId = search?.get("placeholderId");

      if (placeholderId) {
        setHidePlaceholderSavedSearches(
          `${hidePlaceholderSavedSearches},${placeholderId}`
        );
      } else {
        deleteSavedSearch({ id: searchId });
      }
    },
    [
      hidePlaceholderSavedSearches,
      setHidePlaceholderSavedSearches,
      deleteSavedSearch,
    ]
  );
}

export default function useSavedSearches(options = {}) {
  const { modulePath, limit = 0, analyticsVariables = {} } = options;

  const loggedInUser = useLoggedInUser();
  const isLoggedIn = !!loggedInUser;
  const wasLoggedInRef = useRef(isLoggedIn);
  const hasPro = useUserHasPro();

  const [uptoPage, setUptoPage] = useState(0);

  const [hidePlaceholderSavedSearches] = useFrontendSetting(
    HIDE_PLACEHOLDER_SEARCH_KEY,
    ""
  );

  const moduleConfig = useMemo(
    () => ({
      path: modulePath,
      modules: [SAVE_SEARCHES_MODULE],
    }),
    [modulePath]
  );
  const { allModulesLoaded } = useAddModules(moduleConfig);

  const { loadSavedSearches } = useRoutinePromises({
    loadSavedSearches: loadSavedSearchesPromise,
  });

  const loading = useReduxState(
    (state) => selectSavedSearchesLoading(state),
    []
  );
  const loaded = useReduxState((state) => selectSavedSearchesLoaded(state), []);
  const error = useReduxState((state) => selectSavedSearchesError(state), []);

  const pulledSavedSearches = useReduxState(
    (state) => {
      const savedSearches = selectSavedSearches(state);

      if (isLoggedIn && (!savedSearches || savedSearches.size === 0)) {
        // the isPlaceholderSearchDeleted needs to be declared here, otherwise the
        // pulledSavedSearches won't update when hidePlaceholderSavedSearches changes
        const isPlaceholderSearchDeleted = (savedSearch) => {
          const placeholderId = savedSearch.get("placeholderId");

          return (
            !placeholderId ||
            !hidePlaceholderSavedSearches.includes(`,${placeholderId}`)
          );
        };

        if (hasPro) {
          return PRO_PLACEHOLDER_SEARCHES.filter(isPlaceholderSearchDeleted);
        }

        return DEFAULT_PLACEHOLDER_SEARCHES.filter(isPlaceholderSearchDeleted);
      }

      return savedSearches;
    },
    [isLoggedIn, hidePlaceholderSavedSearches]
  );

  const currentDisplayLimit = (uptoPage + 1) * limit;

  useEffect(() => {
    if (
      allModulesLoaded &&
      (!loaded || !wasLoggedInRef.current) &&
      isLoggedIn
    ) {
      loadSavedSearches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, allModulesLoaded]);

  const savedSearches = useMemo(() => {
    if (pulledSavedSearches) {
      const searches = pulledSavedSearches.sort((a, b) =>
        a.get("updated_at") > b.get("updated_at") ? -1 : 1
      );

      if (limit > 0) {
        return searches.slice(0, currentDisplayLimit);
      }

      return searches;
    }

    return null;
  }, [pulledSavedSearches, currentDisplayLimit, limit]);

  const count = (savedSearches && savedSearches.size) || 0;
  const hasMore = count > currentDisplayLimit;

  const onLoadMore = useCallback(() => {
    sendGAEvent({
      action: "SavedSearchLoadMore",
      ...analyticsVariables,
    });
    setUptoPage((upto) => upto + 1);
  }, [analyticsVariables]);

  return {
    savedSearches,
    allModulesLoaded,
    onLoadMore,
    count,
    hasMore,
    loaded,
    loading,
    error,
  };
}
