import { css } from "aphrodite";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

import ProfileNavigationSidebarStyles from "components/Profiles/ProfileNavigationSidebar/ProfileNavigationSidebarStyles";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

const baseStyles = ProfileNavigationSidebarStyles;

function SubtitleCount(props) {
  const { item, newTab, panelTitle, isFirst } = props;
  const { styles } = useStyles(baseStyles, props);

  const location = useLocation();

  const handleSubtitleClick = useCallback(() => {
    sendGAEvent({
      action: "sidebarSubtitleClick",
      panelTitle,
      itemType: item.type,
    });
  }, [item.type, panelTitle]);

  const label = `${item.count || 0} ${
    item.dontPluralize ? item.type : pluralize(item.type, item.count)
  }`;
  const isSubActive = location.pathname.indexOf(item.pathname) === 0;

  return (
    <span>
      {!isFirst && <span>, </span>}
      {item.pathname ? (
        <Link
          to={item.pathname}
          className={css(
            styles.profileNavigationSidebarPanelItemCountLink,
            isSubActive &&
              styles.profileNavigationSidebarPanelItemCountLinkActive
          )}
          target={newTab ? "_blank" : undefined}
          onClick={handleSubtitleClick}
        >
          {label}
        </Link>
      ) : (
        label
      )}
    </span>
  );
}

SubtitleCount.propTypes = {
  item: PropTypes.object,
  newTab: PropTypes.bool,
  panelTitle: PropTypes.node,
  isFirst: PropTypes.bool,
};

SubtitleCount.defaultProps = {
  item: null,
  newTab: false,
  panelTitle: null,
  isFirst: false,
};

export default memo(SubtitleCount);
