import { faBadgeDollar } from "@fortawesome/pro-solid-svg-icons/faBadgeDollar";
import React, { useMemo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import useLoggedInUser from "hooks/useLoggedInUser";

const BrandsActivity = () => {
  const user = useLoggedInUser();

  const brandsWatchedCountConfigs = useMemo(
    () => [
      {
        count: user.get("brand_follow_count", 0),
        type: "watched",
        dontPluralize: true,
        pathname: "/profile/brands",
      },
    ],
    [user]
  );

  return (
    <SidebarLinkPanel
      pathname="/profile/brands"
      icon={faBadgeDollar}
      title="Brands"
      hideSmallIcon
      counts={brandsWatchedCountConfigs}
    />
  );
};

export default React.memo(BrandsActivity);
