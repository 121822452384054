import { memo, useMemo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import useLoggedInUser from "hooks/useLoggedInUser";

import faList from "styles/icons/faList";

const ListsActivity = () => {
  const user = useLoggedInUser();

  const listCount =
    user &&
    user.get("episode_list_count", 0) + user.get("podcast_list_count", 0);

  const listCountConfigs = useMemo(
    () => [
      {
        count: user.get("podcast_list_count", 0),
        type: "podcast list",
        pathname: "/profile/lists/podcasts",
      },
      {
        count: user.get("episode_list_count", 0),
        type: "episode list",
        pathname: "/profile/lists/episodes",
      },
    ],
    [user]
  );

  return (
    <SidebarLinkPanel
      pathname="/profile/lists"
      icon={faList}
      title="Lists"
      count={listCount}
      counts={listCount > 0 && listCountConfigs}
    />
  );
};

export default memo(ListsActivity);
