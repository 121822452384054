import { faBookOpen } from "@fortawesome/free-solid-svg-icons/faBookOpen";
import { faBolt } from "@fortawesome/pro-solid-svg-icons/faBolt";
import { memo, Fragment } from "react";

import PodchaserProLogo from "components/Common/Branding/PodchaserProLogo";
import SidebarSection from "components/Profiles/ProfileNavigationSidebar/SidebarSection";

import AlertsActivity from "./AlertActivity";
import BadgesActivity from "./BadgesActivity";
import BookmarksActivity from "./BookmarksActivity";
import BrandsWatchlist from "./BrandsWatchlist";
import ExportsActivity from "./ExportsActivity";
import FollowsActivity from "./FollowsActivity";
import LikesActivity from "./LikesActivity";
import ListsActivity from "./ListsActivity";
import RatingsActivity from "./RatingsActivity";
import ReviewsActivity from "./ReviewsActivity";
import SearchesActivity from "./SearchesActivity";

import useUserHasPro from "hooks/useUserHasPro";
import useUserHasSponsorPermission from "hooks/useUserHasSponsorPermission";

const proIconStyles = {
  logo: {
    fontSize: ".55rem",
    padding: "0.15rem",
  },
};

const proSectionSubtitleStyles = {
  profileSubtitleImage: {
    position: "absolute",
    right: ".2rem",
    top: "-.1rem",
    marginRight: 0,
  },
};

const HomepageSidebarMyActivity = () => {
  const hasPro = useUserHasPro();
  const hasSponsorPermission = useUserHasSponsorPermission();

  const renderProActivity = () => (
    <SidebarSection
      icon={faBolt}
      image={<PodchaserProLogo styles={proIconStyles} />}
      title="My Pro"
      profileSubtitleStyles={proSectionSubtitleStyles}
    >
      <ListsActivity />
      <ExportsActivity />
      {hasSponsorPermission && <BrandsWatchlist />}
      <SearchesActivity />
    </SidebarSection>
  );

  return (
    <Fragment>
      {hasPro && renderProActivity()}

      <SidebarSection icon={faBookOpen} title="My Activity">
        <FollowsActivity />
        <BookmarksActivity />
        <RatingsActivity />
        <ReviewsActivity />
        <AlertsActivity />
        {!hasPro && <ListsActivity />}
        <LikesActivity />
        {!hasPro && <SearchesActivity />}
        <BadgesActivity />
      </SidebarSection>
    </Fragment>
  );
};

export default memo(HomepageSidebarMyActivity);
