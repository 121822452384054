import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { memo } from "react";

import SidebarSection from "components/Profiles/ProfileNavigationSidebar/SidebarSection";
import HomepageSidebarManagedCreator from "pages/Homepage/HomepageSidebar/HomepageSidebarManagedCreator";

import useLoggedInUser from "hooks/useLoggedInUser";

const HomepageSidebarMyCreators = () => {
  const user = useLoggedInUser();

  return (
    <SidebarSection
      icon={faUsers}
      title="My Creators"
      viewAll="/profile/creators"
    >
      {user.get("managed_creators_summary").map((creator_id) => (
        <HomepageSidebarManagedCreator
          key={creator_id}
          creator_id={creator_id}
        />
      ))}
    </SidebarSection>
  );
};

export default memo(HomepageSidebarMyCreators);
