import { faMicrophoneAlt } from "@fortawesome/free-solid-svg-icons/faMicrophoneAlt";
import { memo } from "react";

import SidebarSection from "components/Profiles/ProfileNavigationSidebar/SidebarSection";
import HomepageSidebarClaimedPodcast from "pages/Homepage/HomepageSidebar/HomepageSidebarClaimedPodcast";

import useLoggedInUser from "hooks/useLoggedInUser";

const HomepageSidebarMyPodcasts = () => {
  const user = useLoggedInUser();

  return (
    <SidebarSection
      icon={faMicrophoneAlt}
      title="My Podcasts"
      viewAll="/profile/claimed"
    >
      {user
        .get("claimed_podcasts_summary")
        .slice(0, 3)
        .map((podcast_id) => (
          <HomepageSidebarClaimedPodcast
            key={podcast_id}
            podcast_id={podcast_id}
          />
        ))}
    </SidebarSection>
  );
};

export default memo(HomepageSidebarMyPodcasts);
