import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { memo, useMemo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";

import useLoggedInUser from "hooks/useLoggedInUser";

const LikesActivity = () => {
  const user = useLoggedInUser();

  const likeCountConfigs = useMemo(
    () => [
      {
        count: user.get("userlist_like_count", 0),
        type: "list",
        pathname: "/profile/liked/lists",
      },
      {
        count: user.get("review_like_count", 0),
        type: "review",
        pathname: "/profile/liked/reviews",
      },
    ],
    [user]
  );

  return (
    <SidebarLinkPanel
      pathname="/profile/liked"
      icon={faHeart}
      title="Likes"
      hideSmallIcon
      counts={likeCountConfigs}
    />
  );
};

export default memo(LikesActivity);
