import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { memo, useMemo } from "react";

import SidebarLinkPanel from "components/Profiles/ProfileNavigationSidebar/SidebarLinkPanel";
import useSavedSearches from "components/Search/SavedSearches/useSavedSearches";

const SearchesActivity = () => {
  const { count: savedSearchCount } = useSavedSearches();

  const searchCountConfigs = useMemo(
    () => [
      {
        count: savedSearchCount,
        type: "saved",
        dontPluralize: true,
        pathname: "/profile/searches",
      },
    ],
    [savedSearchCount]
  );

  return (
    <SidebarLinkPanel
      pathname="/profile/searches"
      icon={faSearch}
      title="Searches"
      hideSmallIcon
      counts={searchCountConfigs}
    />
  );
};

export default memo(SearchesActivity);
